import * as React from 'react';
import OrderIcon from '@material-ui/icons/AttachMoney';
import {
    NumberField,
    TextField,
    DateField,
    useTranslate,
    useGetList,
} from 'react-admin';
import {
    Typography,
    Card,
    CardContent,
    Box,
    Link,
    Stepper,
    Step,
    StepLabel,
    StepContent,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

const useEventStyles = makeStyles({
    stepper: {
        background: 'none',
        border: 'none',
        marginLeft: '0.3em',
    },
});

const EventList = ({ record, basePath }) => {
    const classes = useEventStyles();
    const locale = 'es-ar';

    const { data: orders, ids: orderIds } = useGetList(
        'orders',
        { page: 1, perPage: 100 },
        { field: 'created_at', order: 'DESC' },
        { customer_id: record && record.id }
    );

    const events = mixOrders(orders, orderIds);

    return (
        <>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Historial
                        </Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                {orderIds && orderIds.length > 0 && (
                                    <Box display="flex">
                                        <Box mr="1em">
                                            <OrderIcon
                                                fontSize="small"
                                                color="disabled"
                                            />
                                        </Box>
                                        <Box flexGrow={1}>
                                            <Typography>
                                               {orderIds.length} ordenes
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
                {events.map(event => (
                    <Step
                        key={`${event.type}-${event.data.id}`}
                        expanded
                        active
                        completed
                    >
                        <StepLabel
                            StepIconComponent={() => {
                                const Component =
                                    event.type === 'order'
                                    && OrderIcon
                                return (
                                    <Component
                                        fontSize="small"
                                        color="disabled"
                                        style={{ paddingLeft: 3 }}
                                    />
                                );
                            }}
                        >
                            {new Date(event.date).toLocaleString(locale, {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        </StepLabel>
                        <StepContent>
                            {event.type === 'order' && (
                                <Order
                                    record={event.data}
                                    key={`event_${event.data.id}`}
                                    basePath={basePath}
                                />
                            ) }
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </>
    );
};

const mixOrders = (orders,orderIds) => {
    const events =
        orderIds && orders
            ? orderIds.map(id => ({
                  type: 'order',
                  date: orders[id].created_at,
                  data: orders[id],
              }))
            : [];
    return events;
};

const Order = ({ record, basePath }) => {
    const translate = useTranslate();
    return record ? (
        <>
            <Typography variant="body2" gutterBottom>
                <Link to={`/orders/${record.id}`} component={RouterLink}>
                    Orden #{record.id}
                </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary">
               {record.products_count && record.products_count > 1 ? `${record.products_count} items`: "1 item"}
                &nbsp;-&nbsp;
                $<NumberField 
                    source="cost"
                    record={record}
                    basePath={basePath}
                />
                &nbsp;-&nbsp;
                <span>{translate(`resources.orders.fields.status_field.${record.status}`)}</span>
            </Typography>
        </>
    ) : null;
};

export default Aside;
