import * as React from "react";
import { Datagrid, EditButton, Filter, List, TextField, TextInput } from 'react-admin';

const TagsFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn />
    </Filter>
);

export const TagsList = props => {
    return (
        <List filters={<TagsFilter />} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                <EditButton />
            </Datagrid>
        </List>

    )
};