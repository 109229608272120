import * as React from 'react';
import {
    DateInput,
    Edit,
    BooleanInput,
    TextInput,
    PasswordInput,
    Toolbar,
    FormWithRedirect,
    required,
    email,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Aside from './aside';
import { CustomerTitle } from './title'
import { validatePasswords } from './create';
import { phoneNumberFormat } from './create';

export const CustomerEdit = props => (
    <Edit
        title={<CustomerTitle />}
        aside={<Aside />}
        {...props}
        component="div">
        <CustomerForm />
    </Edit>
);

const CustomerForm = (props) => {

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Editar usuario ({formProps.record && formProps.record.id})
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="first_name"
                                                resource="customers"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="last_name"
                                                resource="customers"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="customers"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="dni"
                                        resource="customers"
                                        fullWidth
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <DateInput
                                                source="birthday"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="phone"
                                                resource="customers"
                                                fullWidth
                                                parse={phoneNumberFormat}
                                            />
                                        </Box>
                                    </Box>

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        Domicilio fiscal
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="address"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="addr_number"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                        >
                                            <TextInput
                                                source="addr_floor_door"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>



                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="province"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={2}>
                                            <TextInput
                                                source="zipcode"
                                                resource="customers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        Cambiar Contraseña
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="customers"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password_confirmation"
                                                resource="customers"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Newsletter
                                    </Typography>
                                    <BooleanInput
                                        source="newsletter"
                                        resource="customers"
                                    />
                                    {formProps.record && !formProps.record.email_verified_at &&
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Email verificado
                                            </Typography>
                                            <BooleanInput
                                                source="email_verified"
                                                label=""
                                                initialValue={formProps.record ? formProps.record.email_verified_at : false}
                                                resource="customers"
                                                color="default"
                                                options={{
                                                    checkedIcon: <CheckCircleIcon />,
                                                }}
                                            />
                                        </>
                                    }
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            pristine={formProps.pristine}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="customers"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];