import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';

const useStyles = makeStyles({
    rightAlignedCell: { textAlign: 'right' },
});

const Basket = ({ record }) => {
    const classes = useStyles();

    if (!record) return null;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Referencia
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Precio U.
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Cantidad
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Total
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.basket?.map(
                    (item) =>
                    item && (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Link to={`/products/${item.id}`}>
                                        {item.sku}
                                    </Link>
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.product_order.cost.toLocaleString('es-ar', {
                                        style: 'currency',
                                        currency: 'ARS',
                                        minimumFractionDigits: 2
                                    })}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.product_order.quantity} 
                                    {item.sale_unit === 'meters' ? item.product_order.quantity != 1 ? ' metros' : ' metro' : item.product_order.quantity != 1 ? ' unidades' : ' unidad' } 
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {(
                                        item.product_order.cost *
                                        item.product_order.quantity
                                    ).toLocaleString('es-ar', {
                                        style: 'currency',
                                        currency: 'ARS',
                                        minimumFractionDigits: 2
                                    })}
                                </TableCell>
                            </TableRow>
                        )
                )}
            </TableBody>
        </Table>
    );
};

export default Basket;
