import * as React from "react";
import { Datagrid, EditButton, Filter, List, TextField, TextInput } from 'react-admin';

const ShippingsFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn />
    </Filter>
);

const PriceTextField = ({ record }) => {
    return <span>{`${(Number(record.price)).toLocaleString('es-ar', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2})}`}</span>;
};

const WeightTextField = ({ record, source }) => (
    <span>{`${record[source]} g`}</span>
);

export const ShippingsList = props => {
    return (
        <List filters={<ShippingsFilter />} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="province" />
                <WeightTextField source="min_weight" record={record => record}/>
                <WeightTextField source="max_weight" record={record => record}/>
                <PriceTextField source="price" record={record => record}/>
                <EditButton />
            </Datagrid>
        </List>
    )
};