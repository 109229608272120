import * as React from "react";
import {Edit, SimpleForm, TextInput, required} from 'react-admin';

import {CategoriesTitle} from './title'

export const CategoriesEdit = props => (
    <Edit title={<CategoriesTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="description"/>
        </SimpleForm>
    </Edit>
);