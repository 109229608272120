import * as React from 'react';

const ShippingField = ({ record }) => {
        if (!record) return null;

        if (record.shipping_method == "local"){
                return (
                        <span style={{textTransform: 'capitalize'}}>
                                Retira en sucursal
                        </span>
                )
        }
            return (
                <span style={{textTransform: 'capitalize'}}>
                        {record.address} {record.addr_number} {record.addr_floor_door} - {record.city}, {record.province} 
                </span>
            )  

};

export default ShippingField;