import React from "react";
import LogoHeader from './assets/img/logo-header.png';

const Logo = () => {
    return (
        <img width="150px" src={LogoHeader} alt="logo"/>
    );
};

export default Logo;
