import { Button, useNotify } from "react-admin";
import PublishIcon from "@material-ui/icons/Publish";
import { apiUrl } from "../../utils/commosHttp";

export const ImportButton = () => {
  const notification = useNotify();
  const handleImport = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const token = localStorage.getItem("token");
      const response = await fetch(apiUrl + "/products/import", {
        method: "POST",
        headers:{
          Authorization: `Bearer  ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        notification("ra.notification.import_success", { type: "info" });
      } else {
        notification("ra.notification.import_error", { type: "error" });
      }
    } catch (error) {
      console.error("Error subiendo archivo file:", error);
    }
  };
  return (
    <>
      <input
        style={{ display: "none" }}
        id="fileInput"
        type="file"
        accept=".xlsx"
        onChange={(event) => handleImport(event.target.files[0])}
      />
      <label htmlFor="fileInput">
        <Button
          startIcon={<PublishIcon />}
          variant="outlined"
          label="Subir"
          component="span"
          style={{ marginRight: "8px" }}
        />
      </label>
    </>
  );
};
