import * as React from 'react';
import classnames from 'classnames';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, useTranslate } from 'react-admin';

const useStyles = makeStyles({
    container: { minWidth: '35em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
});

const Totals = ({ record }) => {
    const classes = useStyles();

    return (
        <Table className={classes.container}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        Sum
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        {record && Number(record.cost).toLocaleString('es-ar', {
                            style: 'currency',
                            currency: 'ARS',
                            minimumFractionDigits: 2
                        })}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        Envío
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        {record && Number(record.shipping_cost).toLocaleString('es-ar', {
                            style: 'currency',
                            currency: 'ARS',
                            minimumFractionDigits: 2
                        })}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.boldCell}>
                        Total
                    </TableCell>
                    <TableCell
                        className={classnames(
                            classes.boldCell,
                            classes.rightAlignedCell
                        )}
                    >
                        {record && (Number(record.cost) + Number(record.shipping_cost)).toLocaleString('es-ar',
                            {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2
                            }
                        )}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default Totals;
