import * as React from "react";
import { Create, SimpleForm, NumberInput, SelectInput, required, minValue } from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import { ShippingsTitle } from './title'


export const SelectProvince = ({ record = {}, source }) => {
    return <SelectInput
        label="resources.shippings.fields.province"
        source="province"
        validate={[required()]}
        choices={[
            {
                id: 'Buenos Aires',
                name: 'Buenos Aires',
            },
            {
                id: 'Ciudad Autonoma de Buenos Aires',
                name: 'Ciudad Autónoma de Buenos Aires',
            },
            {
                id: 'Catamarca',
                name: 'Catamarca',
            },
            {
                id: 'Chaco',
                name: 'Chaco',
            },
            {
                id: 'Chubut',
                name: 'Chubut',
            },
            {
                id: 'Cordoba',
                name: 'Córdoba',
            },
            {
                id: 'Corrientes',
                name: 'Corrientes',
            },
            {
                id: 'Entre Rios',
                name: 'Entre Ríos',
            },
            {
                id: 'Formosa',
                name: 'Formosa',
            },
            {
                id: 'Jujuy',
                name: 'Jujuy',
            },
            {
                id: 'La Pampa',
                name: 'La Pampa',
            },
            {
                id: 'La Rioja',
                name: 'La Rioja',
            },
            {
                id: 'Mendoza',
                name: 'Mendoza',
            },
            {
                id: 'Misiones',
                name: 'Misiones',
            },
            {
                id: 'Neuquen',
                name: 'Neuquén',
            },
            {
                id: 'Rio Negro',
                name: 'Río Negro',
            },
            {
                id: 'Salta',
                name: 'Salta',
            },
            {
                id: 'San Juan',
                name: 'San Juan',
            },
            {
                id: 'San Luis',
                name: 'San Luis',
            },
            {
                id: 'Santa Cruz',
                name: 'Santa Cruz',
            },
            {
                id: 'Santa Fe',
                name: 'Santa Fe',
            },
            {
                id: 'Santiago del Estero',
                name: 'Santiago del Estero',
            },
            {
                id: 'Tierra del Fuego',
                name: 'Tierra del Fuego',
            },
            {
                id: 'Tucuman',
                name: 'Tucumán',
            },
        ]}
    />
}

export const ShippingsCreate = props => (
    <Create title={<ShippingsTitle />} {...props}>
        <SimpleForm>
            <SelectProvince source="province" />
            <NumberInput
                source={'min_weight'}
                validate={[required(), minValue(0)]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            g/m
                        </InputAdornment>
                    ),
                }}
            />
            <NumberInput
                source={'max_weight'}
                validate={[required(), minValue(0)]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            g/m
                        </InputAdornment>
                    ),
                }}
            />
            <NumberInput
                    source={'price'}
                    label="Precio"
                    validate={required()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        ),
                    }}
                />
        </SimpleForm>
    </Create>
);