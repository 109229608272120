import React, { useEffect } from 'react';
import { Title } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export const MediaLibrary = () => {
    useEffect(() => {
        window.initElFinder(`${process.env.REACT_APP_API_HOST}/elfinder/connector`)
    });

    return (
        <Card>
            <Title title="Biblioteca multimedia" />
            <CardContent>
                <div id="elfinder"></div>
            </CardContent>
        </Card>
    )
};
