import * as React from 'react';
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import { Link } from 'react-router-dom';
import { useQueryWithStore } from 'react-admin';
import { subDays } from 'date-fns';

import CardWithIcon from './CardWithIcon';

const NewCustomers = () => {
    const classes = useStyles();

    const aMonthAgo = subDays(new Date(), 30);
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    aMonthAgo.setHours(0);
    aMonthAgo.setMinutes(0);
    aMonthAgo.setSeconds(0);
    aMonthAgo.setMilliseconds(0);

    const { loaded, data: customers, total: total } = useQueryWithStore({
        type: 'getList',
        resource: 'customers',
        payload: {
            filter: { created_at_gte: aMonthAgo.toISOString() },
            sort: { field: 'created_at', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    if (!loaded) return null;

    const nb = total ? total : 0;
    
    return (
        <CardWithIcon
            to="/customers"
            icon={CustomerIcon}
            title={"Nuevos clientes"}
            subtitle={nb}
        >
            <List>
                {customers
                    ? customers.map((record) => (
                        <ListItem
                            button
                            to={`/customers/${record.id}`}
                            component={Link}
                            key={record.id}
                        >
                            <ListItemAvatar>
                                <Avatar src={`${record.avatar}?size=32x32`} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${record.first_name} ${record.last_name}`}
                            />
                        </ListItem>
                    ))
                    : null}
            </List>
            <Box flexGrow="1">&nbsp;</Box>
            <Button
                className={classes.link}
                component={Link}
                to="/customers"
                size="small"
                color="primary"
            >
                <Box p={1} className={classes.linkContent}>
                    {"Ver todos los clientes"}
                </Box>
            </Button>
        </CardWithIcon>
    );
};

const useStyles = makeStyles(theme => ({
    link: {
        borderRadius: 0,
    },
    linkContent: {
        color: theme.palette.primary.main,
    },
}));

export default NewCustomers;
