import * as React from "react";
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    TextInput,
    DateField,
    SelectInput,
    BooleanField,
} from 'react-admin';
import ShippingField from "../../components/fields/order/ShippingField";
import CostField from "../../components/fields/order/CostField";
import StatusField from "../../components/fields/order/StatusField";


const OrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn />
        <SelectInput
            resource="orders"
            source="status"
            choices={[
                {
                    id: 'pending_payment',
                    name: 'Pendiente de pago',
                },
                {
                    id: 'processing',
                    name: 'Procesando',
                },
                {
                    id: 'complete',
                    name: 'Completado',
                },
                {
                    id: 'cancelled',
                    name: 'Cancelado',
                },
                {
                    id: 'failed',
                    name: 'Error',
                },
            ]}
            alwaysOn
        />
        <TextInput source="city" />
        <TextInput source="province" />
    </Filter>
);

const NameTextField = ({ record }) => {
    if (record) {
        return (<span>{`${record["first_name"]} ${record["last_name"]}`}</span>);
    }
    return null;
};

const ReferenceTextField = ({ record }) => {
    if (record) {
        return (<span>{'#' + (String)(record.id).padStart(5, "0")}</span>);
    }
    return null;
};

const PaymentMethodField = ({ record }) => {
    if (record) {
        if (record["payment_method"] == 'bank') {
            return (<span>{`Transferencia`}</span>);
        } else if (record["payment_method"] == 'mp') {
            return (<span>{`Mercado Pago`}</span>);
        } else {
            return (<span>{`${record["payment_method"]}`}</span>);
        }
    }
    return null;
};

const BooleanNumField = ({ record = {}, source }) => {
    const theRecord = { ...record };
    theRecord[source + 'Num'] = !!parseInt(record[source]);
    return <BooleanField record={theRecord} source={source + 'Num'} />
}

export const OrdersList = props => {

    return (
        <List bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<OrdersFilter />} {...props}>
            <Datagrid rowClick="edit">
                <ReferenceTextField sortBy="id" source="reference" record={record => record} />
                <DateField source="created_at" showTime />
                <ReferenceField source="customer_id" reference="customers" label={"Clientes"}>
                    <NameTextField record={record => record} />
                </ReferenceField>
                <ShippingField sortBy="address" source="shipping_address" record={record => record} />
                <CostField source="cost" />
                <PaymentMethodField source="payment_method" record={record => record} />
                <BooleanNumField source="payed" label="Pagado" />
                <StatusField source="status" />
                <EditButton />
            </Datagrid>
        </List>

    )
};