import * as React from "react";
import {Create, SimpleForm, TextInput, required } from 'react-admin';

import {CategoriesTitle} from './title'

export const CategoriesCreate = props => (
    <Create title={<CategoriesTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name"  validate={[required()]}/>
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);