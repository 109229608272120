import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: `#498177`,
        color: '#fff',
        padding: 20,
        marginTop: theme.spacing(2),
        marginBottom: '16px',
    },
    actions: {
        padding: 0,
        flexWrap: 'wrap',
        '& a': {
            marginTop: '16px',
        },
    },
}));

const Welcome = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        Bienvenidos a Eme Telas
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            Este es el administrador de la tienda electrónica de Eme Telas.
                        </Typography>
                    </Box>
                    <CardActions className={classes.actions}>
                        <Button
                            variant="contained"
                            href="http://emetelas.com.ar"
                            target="_blank"
                            startIcon={<HomeIcon />}
                        >
                            Visitar sitio
                        </Button>
                    </CardActions>
                </Box>

                <Box
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    className={classes.media}
                    width="16em"
                    height="9em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;
