import { fetchUtils } from 'react-admin';

export const apiUrl = process.env.REACT_APP_API_HOST_ADMIN;

export const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!options.headers) {
        options.headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        });
    }

    return fetchUtils.fetchJson(url, options);
}