import * as React from "react";

import Grid from '@material-ui/core/Grid';
import { InputAdornment } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';
import {
    NumberInput, SelectInput, TextInput, required,
    ArrayInput, SimpleFormIterator, FormDataConsumer,
    ReferenceArrayInput, CheckboxGroupInput, minValue
} from 'react-admin';
import { ImageField } from '../fields/ImageField';
import TagsField from "../fields/TagsField";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightBold,
        },
        accordion: {
            background: '#fafafa',
            width: '100%',
            marginTop: '12px',
            '&$expanded': {
                margin: 'auto',
            },
        },
        flex: {
            display: 'flex',
            alignItems: 'center'
        },
        col1: { width: '100%' },
    }),
);

const ProductForm = (props) => {
    const classes = useStyles();
    const { productID, useInitialValues } = props;
    const { values } = useFormState();

    const getTextForQuantity = () => {
        if (values.main_sale_unit === 'meters') {
            return "metros"
        }
        return "unidades";
    }

    const getTextForWeight = () => {
        if (values.main_sale_unit === 'meters') {
            return "gramos/metro"
        }
        return "gramos/unidad";
    }

    const getTextForPrice = () => {
        if (values.main_sale_unit === 'meters') {
            return "precio/metro"
        }
        return "precio/unidad";
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextInput
                    className={classes.col1}
                    source={productID + '_sku'}
                    label={'SKU'}
                    initialValue={useInitialValues && values.main_sku ? values.main_sku : undefined}
                    validate={required()} />
                <TextInput
                    className={classes.col1}
                    source={productID + '_name'}
                    label="Nombre"
                    initialValue={useInitialValues && values.main_name ? values.main_name : undefined}
                    validate={required()} />
                <TextInput
                    className={classes.col1}
                    source={productID + '_description'}
                    label="Descripción"
                    initialValue={useInitialValues && values.main_description ? values.main_description : undefined}
                    multiline
                    rows={3}
                    validate={required()} />
                <SelectInput
                    source={productID + '_sale_unit'}
                    label="Unidad de venta"
                    initialValue={useInitialValues && values.main_sale_unit ? values.main_sale_unit : 'meters'}
                    choices={[
                        { id: 'meters', name: 'Metros' },
                        { id: 'unit', name: 'Unidad' },
                    ]} validate={required()} fullWidth
                />
                <NumberInput
                    source={productID + '_price'}
                    label="Precio"
                    initialValue={useInitialValues && values.main_price ? values.main_price : undefined}
                    validate={required()}
                    className={classes.col1}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                {getTextForPrice()}
                            </InputAdornment>
                        ),
                    }}
                />
                <NumberInput
                    source={productID + '_discount'}
                    label="Descuento"
                    initialValue={useInitialValues && values.main_discount ? values.main_discount : 0}
                    className={classes.col1}
                    format={v => v * 100}
                    parse={v => parseFloat(v) / 100}
                    validate={[minValue(0)]}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                %
                            </InputAdornment>
                        ),
                    }}
                />
                <NumberInput
                    className={classes.col1}
                    source={productID + '_quantity'}
                    label="Cantidad"
                    initialValue={useInitialValues && values.main_quantity ? values.main_quantity : undefined}
                    validate={required()}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {getTextForQuantity()}
                            </InputAdornment>
                        ),
                    }}
                />
                <NumberInput
                    className={classes.col1}
                    source={productID + '_weight'}
                    label="Peso"
                    initialValue={useInitialValues && values.main_weight ? values.main_weight : undefined}
                    validate={[required(), minValue(0)]}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {getTextForWeight()}
                            </InputAdornment>
                        ),
                    }}
                />
                <TextInput
                    className={classes.col1}
                    source={productID + '_fabric'}
                    label="Material"
                    initialValue={useInitialValues && values.main_fabric ? values.main_fabric : undefined}
                />
                <SelectInput
                    className={classes.col1}
                    source={productID + '_status'}
                    label="Estado"
                    initialValue={useInitialValues && values.main_status ? values.main_status : undefined}
                    choices={[
                        {
                            id: 'available',
                            name: 'Disponible',
                        },
                        {
                            id: 'not_available',
                            name: 'No disponible',
                        },
                    ]}
                />

                <ReferenceArrayInput label="Categorías" source="categories" reference="categories" fullWidth>
                    <CheckboxGroupInput optionText="name" />
                </ReferenceArrayInput>

                <TagsField source="tags" record={props.record} fullWidth />
                <TextInput source="keywords" label="Palabras similares" fullWidth helperText="Ingrese las palabras separadas por ,"/>
            </Grid>
            <Grid item xs={6}>
                <ArrayInput label={"Imágenes"} source={productID + '_images'}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({
                                formData, // The whole form data
                                scopedFormData, // The data for this item of the ArrayInput
                                getSource, // A function to get the valid source inside an ArrayInput
                                ...rest
                            }) =>
                                scopedFormData && scopedFormData.path ? (
                                    <ImageField source={productID + '_images'} path={scopedFormData.path} index={getSource('path')} useInitialValues={useInitialValues} />
                                ) : <ImageField source={productID + '_images'} path={undefined} index={getSource('path')} useInitialValues={false} />
                            }
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
        </Grid >
    );
}

export default ProductForm;