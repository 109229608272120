import * as React from "react";
import { useEffect } from 'react';
import { CheckboxGroupInput } from 'react-admin'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormState, useField } from 'react-final-form';

const useStyles = makeStyles(() =>
    createStyles({
        containerTaxonomies: {
            fontStyle: 'italic'
        },
        taxName: {
            fontWeight: 'bold',
            fontStyle: 'normal'
        },
        containerAttributes: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& legend': { display: 'none' },
            '& p': { display: 'none' },
            '& fieldset': {
                margin: '0 0 0 20px'
            }
        },
    }),
);

const generateProducts = (...arrays) => {
    const _inner = (...args) => {
        if (args.length > 1) {
            let arr2 = args.pop(); // arr of arrs of elems
            let arr1 = args.pop(); // arr of elems
            return _inner(...args,
                arr1.map(e1 => arr2.map(e2 => [e1, ...e2]))
                    .reduce((arr, e) => arr.concat(e), [])
            );
        } else {
            return args[0];
        }
    };
    return _inner(...arrays, [[]]);
}

const Attribute = ({ record }) => {
    return (
        <div key={record.taxonomy_id} className="attributes">
            {record.value}
        </div>
    )
}

const TaxonomySelector = ({ taxonomies }) => {
    const classes = useStyles();
    const { values } = useFormState();
    const { input } = useField('variations');
    useEffect(() => {
        if (values.taxonomies) {
            console.log('Handle taxx')
            let selectedValues = [];
            const productsIds = [];
            for (let key in values.taxonomies) {
                if(values.taxonomies[key].length > 0){
                    selectedValues.push(values.taxonomies[key])
                }
            }
            selectedValues = generateProducts(...selectedValues);
            selectedValues.map(elem => elem.length > 0 && productsIds.push('product_' + elem.join('_')));
            input.onChange(productsIds);
        }
    }, [values.taxonomies]);

    if (taxonomies) {
        return (
            <div className={classes.containerTaxonomies}>
                {
                    taxonomies.map(taxonomy => {
                        return (
                            <div className={classes.containerAttributes} key={taxonomy.name}>
                                <div className={classes.taxName}>{taxonomy.name}</div>
                                <CheckboxGroupInput
                                    source={`taxonomies.tax_${taxonomy.id}`}
                                    choices={taxonomy.attributes}
                                    optionText={<Attribute />}
                                    key={taxonomy.id}
                                    id={`tax_${taxonomy.id}`}
                                    label={taxonomy.name}
                                />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return <div />
}

export default TaxonomySelector;