import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    PasswordInput,
    required,
    email,
    BooleanInput,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { CustomerTitle } from './title'

export const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-]+/g, '');
    let x;
    if (newNum.length <= 3) {
        x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6)
    } else {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6) + "-" + newNum.slice(6, 10)
    }
    return x;
};

export const validatePasswords = ({
    password,
    password_confirmation,
}) => {
    const errors = {};

    if (password && password !== password_confirmation) {
        errors.password_confirmation = [
            'resources.customers.errors.password_mismatch',
        ];
    }

    return errors;
};

export const CustomerCreate = props => {

    return (
        <Create {...props} title={<CustomerTitle />}>
            <SimpleForm validate={validatePasswords}>
                <div fullWidth>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                        <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                            <Typography variant="h6" gutterBottom>
                                Agregar nuevo cliente
                        </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="first_name"
                                        resource="customers"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="last_name"
                                        resource="customers"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <TextInput
                                type="email"
                                source="email"
                                resource="customers"
                                validate={[email(), required()]}
                                fullWidth
                            />
                            <TextInput
                                source="dni"
                                resource="customers"
                                fullWidth
                            />
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <DateInput
                                        source="birthday"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="phone"
                                        resource="customers"
                                        fullWidth
                                        parse={phoneNumberFormat}
                                    />
                                </Box>
                            </Box>

                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                                Domicilio fiscal
                        </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={2}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="address"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="addr_number"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                >
                                    <TextInput
                                        source="addr_floor_door"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>



                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={2}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="province"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={2}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="city"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={2}>
                                    <TextInput
                                        source="zipcode"
                                        resource="customers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>

                            <Box mt="1em" />
                            <Typography variant="h6" gutterBottom>
                                Cambiar Contraseña
                        </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <PasswordInput
                                        source="password"
                                        resource="customers"
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <PasswordInput
                                        source="password_confirmation"
                                        resource="customers"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            flex={1}
                            ml={{ xs: 0, lg: '1em' }}
                            mt={{ xs: '1em', lg: 0 }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Newsletter
                        </Typography>
                            <div>
                                <BooleanInput
                                    source="newsletter"
                                    resource="customers"
                                />
                            </div>
                        </Box>
                    </Box>
                </div>
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];