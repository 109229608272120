import { FunctionField } from "react-admin"
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
  not_available: { color: 'red' },
  available: { color: 'green' },
});

export const StatusField = (props) => {
  const classes = useStyles();
  
  const { status } = props.record;

  return (
    <FunctionField
      render={() => 
        status == 'available' ? 'Disponible'
        : 'No disponible'
      }
      className={classnames({
        [classes.available]: status == 'available',
        [classes.not_available]: status == 'not_available',
      })}
      {...props}
    />
  )
}