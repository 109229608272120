import * as React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import Menu from './Menu';

const customtheme = {
    overrides: {
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #014548',
            },
        },
    },
};

const CustomLayout = (props) => {
    return(
        <Layout {...props} appBar={CustomAppBar} menu={Menu} theme={customtheme} />
    )
}


export default CustomLayout;