import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput } from 'react-admin';
import { Typography } from '@material-ui/core';
import { ModalElFinder } from "../modals/ModalElFinder";
import { useField } from 'react-final-form';
import { useFormState, useForm } from 'react-final-form';

const useStyles = makeStyles({
    img: {
        width: '100%',
        marginTop: "8px",
        maxWidth: '150px',
        maxHeight: '150px'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    }
});

export const ImageField = ({useInitialValues,path,index}) => {
    const classes = useStyles();
    const { values } = useFormState();
    const { input } = useField(index);

    const handleCallback = (file) => {
        input.onChange(file);
    };

    const handleLabelButton = () => {
        return path ? 'Actualizar' : 'Agregar';
    };
    
    return (
        <>
            <img src={path} className={classes.img} />
            <div className={classes.flex}>
                <TextInput
                    source={index}
                    label={"Url de la imagen"}
                    initialValue={useInitialValues && values[index] ? values[index] : undefined}
                    fullWidth
                />
                <ModalElFinder callback={handleCallback} labelButton={handleLabelButton()}/> 
            </div>
        </>
    );
};