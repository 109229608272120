import { Button, useNotify } from "react-admin";
import { apiUrl } from "../../utils/commosHttp";
import GetAppIcon from "@material-ui/icons/GetApp";

export const ExportButton = () => {
  const notification = useNotify();

  const exportProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await fetch(`${apiUrl}/products/export`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer  ${token}`,
          },
        });
        if (!response.ok) {
          notification("ra.notification.download_error", { type: "error" });
          return;
        }
        const fileName =
          "productos_" +
          new Date()
            .toLocaleString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
            .replace(/[\/ ,:]/g, "_") +
          ".xlsx";
        const blob = await response.blob();
        const downloadurl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadurl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.log("usuario no autenticado");
      }
    } catch (error) {
      console.error("Error en la descarga del archivo", error);
    }
  };

  const handleExport = async () => {
    await exportProducts();
  };

  return (
    <Button
      startIcon={<GetAppIcon />}
      label="Descargar"
      variant="outlined"
      onClick={handleExport}
    />
  );
};
