import * as React from "react"
import { useState, useEffect } from 'react';
import {
    useEditController,
    Edit,
    EditContextProvider,
    SelectInput,
    SimpleForm,
    TextInput,
    Loading,
    required
} from 'react-admin';
import { ProductTitle } from './title';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { useFormState } from 'react-final-form';
import dataProvider from '../../providers/dataProvider';
import ProductForm from '../../components/forms/productForm'
import TaxonomySelector from '../../components/panels/taxonomySelector'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightBold,
        },
        accordion: {
            background: '#fafafa',
            width: '100%'
        },
        accordionTaxonomies: {
            marginTop: '32px !important'
        },
        accordionForms: {
            flexDirection: 'column'
        },
        col1: { width: '100%' },
        hidden: { display: 'none' }
    })
);

const getProductName = (productID, taxonomies) => {
    const attrs = productID.split('_');
    attrs.shift();
    let productName = ['Variación:'];
    attrs.forEach(idAttr => {
        for (let key in taxonomies) {
            const taxonomy = taxonomies[key];
            const attr = taxonomy.attributes.find(tx => tx.id === parseInt(idAttr));
            if (attr) {
                productName.push(attr.value);
            }
        }
    });
    return productName.join(' ')
}

const PanelTaxonomies = ({ taxonomies, record }) => {
    const classes = useStyles();
    const { values } = useFormState();
    
    if (values.type === 'variable') {
        return (
            <Accordion className={classes.accordionTaxonomies} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography className={classes.heading}>Taxonomias</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TaxonomySelector taxonomies={taxonomies} />
                </AccordionDetails>
            </Accordion>
        );
    }
    return <div />;
}

const PanelProductBase = (props) => {
    const classes = useStyles();
    const { values } = useFormState();

    return (
        <Accordion className={classes.accordion} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>Producto Base</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ProductForm productID={'main'} {...props}/>
            </AccordionDetails>
        </Accordion>
    );
}

const PanelVariations = ({ taxonomies }) => {
    const classes = useStyles();
    const { values } = useFormState();

    if (values.type === 'variable' && values.variations && values.variations.length > 0) {
        return (
            <Accordion className={classes.accordionTaxonomies}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3b-content" id="panel3b-header">
                    <Typography className={classes.heading}>Variaciones</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionForms}>
                    {values.variations.map(productID =>
                        <div key={productID} >
                            <h3>{getProductName(productID, taxonomies)}</h3>
                            <ProductForm productID={productID} useInitialValues={true} />
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }
    return <div />;
}

const PanelTitle = ({ record }) => {
    const classes = useStyles();

    if (record){
        return (
            <Typography className={classes.heading}>
                Editar Producto "{record.main_name}"
            </Typography>
        );
    }
}

export const ProductEdit = (props) => {
    const classes = useStyles();
    const [taxonomies, setTaxonomies] = useState([]);
    const [selectedTax, setSelectedTax] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getTaxonomies()
            .then((data) => {
                setTaxonomies(data.data);
            })
            .catch(error => {
                setTaxonomies([])
                setError(error);
            })
            .finally(() => setLoading(false))
    }, []);

    return (
        loading ? <Loading /> :
        <Edit title={<ProductTitle />} {...props}>
            <SimpleForm>
                <PanelTitle {...props}/>

                <Grid className={classes.root} container spacing={3}>
                    <Grid item xs={6}>
                        <SelectInput source="type" label="Tipo de producto" choices={[
                            { id: 'simple', name: 'Simple' },
                            /* { id: 'variable', name: 'Variable' },
                            { id: 'combo', name: 'Combo' }, */
                        ]} validate={required()} fullWidth />
                    </Grid>
                </Grid>

                <PanelProductBase {...props} />

                <PanelTaxonomies taxonomies={taxonomies} />

                <PanelVariations taxonomies={taxonomies} />
                <TextInput
                    className={classes.hidden}
                    source="variations"
                    label="variations" />
            </SimpleForm>
        </Edit>
    );
}