import { stringify } from 'query-string';

import { apiUrl, httpClient } from '../utils/commosHttp'

const transformData = (data) => {
    const replacements = {
        'sku': 'main_sku',
        'name': 'main_name', 
        'description': 'main_description', 
        'discount': 'main_discount', 
        'price': 'main_price', 
        'quantity': 'main_quantity', 
        'fabric': 'main_fabric',
        'weight': 'main_weight',
        'status': 'main_status',
        'images': 'main_images',
        'sale_unit' : 'main_sale_unit',
    };

    const replacedItems = Object.keys(data).map((key) => {
        const newKey = replacements[key] || key;
        return { [newKey] : data[key] };
    });
    let newData = replacedItems.reduce((a, b) => Object.assign({}, a, b));
    if (data && data.type == "variable"){
        newData.variations = [];
        newData.taxonomies = {};
        data.children.map(elem => {
            const prefijo = 'product' + elem.attributes.reduce((a, o) => (a+= `_${o.id}`, a), '');
            elem.attributes.map((elem) => {
                if (typeof newData.taxonomies[`tax_${elem.taxonomy.id}`] == 'undefined') {
                    newData.taxonomies[`tax_${elem.taxonomy.id}`] = [elem.id];
                } else if(!newData.taxonomies[`tax_${elem.taxonomy.id}`].includes(elem.id)) {
                    newData.taxonomies[`tax_${elem.taxonomy.id}`].push(elem.id);
                }
            });

            newData[prefijo+'_id'] = elem.id;
            newData[prefijo+'_sku'] = elem.sku;
            newData[prefijo+'_name'] = elem.name;
            newData[prefijo+'_description'] = elem.description;
            newData[prefijo+'_discount'] = elem.discount;
            newData[prefijo+'_price'] = elem.price;
            newData[prefijo+'_quantity'] = elem.quantity;
            newData[prefijo+'_fabric'] = elem.fabric;
            newData[prefijo+'_weight'] = elem.weight;
            newData[prefijo+'_status'] = elem.status;
            newData[prefijo+'_images'] = elem.images;
            newData[prefijo+'_sale_unit'] = elem.sale_unit;
        })
    }
    return newData;
}

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sortBy: field,
            sortType: order,
            page: page,
            size: perPage,
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => { 
            if (resource == "products"){
                return { data : transformData(json)};
            }
            return({ data: json})
        })
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.data }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sortBy: field,
            sortType: order,
            page: page,
            size: perPage,
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    getTaxonomies: () => {
        const url = `${apiUrl}/taxonomies/all`;
        return httpClient(url).then(({ headers, json }) => ({
            data: json,
        }));
    }
};
