import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const PendingOrders = ({ orders = [], customers = {} }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader title={"Órdenes Pendientes"} />
            <List dense={true}>
                {orders.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/orders/${record.id}`}
                    >
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                            primary={new Date(record.created_at).toLocaleString(
                                'es-ar'
                            )}
                            secondary={
                                customers[record.customer_id] ? 
                                    `${customers[record.customer_id].last_name}, ${record.basket_count} items.` 
                                : ''
                            }
                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                            {record && Number(record.cost).toLocaleString('es-ar', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2
                            })}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default PendingOrders;
