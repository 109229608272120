import * as React from "react";
import { List, Datagrid, TextField, EditButton, Filter, TextInput, TopToolbar, CreateButton } from 'react-admin';
import { StatusField } from '../../components/fields/StatusField';
import { ImportButton } from "../../components/export/ImportButton";
import { ExportButton } from "../../components/export/ExportButton";

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn />
    </Filter>
);

const PriceTextField = ({ record }) => {
    if (!record) return "";
    if (record.discount > 0) {
        return (<><del style={{ color: "#9a9a9a" }}>{`${(Number(record.price)).toLocaleString('es-ar', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2 })}`}</del>
            <br /><span>{`${(Number(record.price_discount)).toLocaleString('es-ar', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2 })}`}</span></>);
    } else {
        return <span>{`${(Number(record.price)).toLocaleString('es-ar', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2 })}`}</span>;
    }
};

const StockTextField = ({ record }) => {
    if (!record) return "";
    if (record.sale_unit === "meters") {
        return <span>{`${record["quantity"]} metros`}</span>
    }
    return <span>{`${record["quantity"]} unidades`}</span>
};

const WeightTextField = ({ record }) => {
    if (!record) return "";
    return <span>{`${record["weight"]} gramos`}</span>
};

const ActionsProducts = ({ ...props }) => {
    return (
        <TopToolbar style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
            {props?.filters && React.cloneElement(props?.filters, { context: "button" })}
            <ImportButton />
            <ExportButton />
            <CreateButton
                variant="contained"
                size="medium"
                style={{ marginLeft: '8px' }}
            />
        </TopToolbar>
    )
}

export const ProductList = props => {
    return (
        <List filters={<ProductFilter />} {...props} actions={<ActionsProducts {...props} />}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="sku" />
                <TextField source="name" />
                <PriceTextField source="price" record={record => record} />
                <StockTextField source="quantity" record={record => record} />
                <WeightTextField source="weight" record={record => record} />
                <StatusField source="status" />
                <EditButton />
            </Datagrid>
        </List>

    )
};