import React, { useState, useEffect } from 'react';
import {
    Button,
} from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

export const ModalElFinder = ({ callback, labelButton }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSetInputImage = (file) => {
        callback(file);
        setShowDialog(false);
    };

    useEffect(() => {
        if (showDialog) {
            setTimeout(function () {
                window.initElFinderModalImage(handleSetInputImage, `${process.env.REACT_APP_API_HOST}/elfinder/connector`);
            }, 200);
        }
    }, [showDialog]);

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleClick} label={labelButton} style={{top: "-6px",padding: "8px", width: "100%",maxWidth: "min-content",marginLeft: "15px"}}>
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                maxWidth={"lg"}
                onClose={handleCloseClick}
                aria-label="Seleccionar imagen">
                <DialogTitle>Seleccionar imagen</DialogTitle>
                <DialogContent>
                    <div id="elfinderImageFeatured"></div>
                </DialogContent>
            </Dialog>
        </>
    );
}
