import * as React from "react";
import { Edit, SimpleForm, NumberInput, required, minValue } from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import {ShippingsTitle} from './title'
import { SelectProvince } from './create';

export const ShippingsEdit = props => (
    <Edit title={<ShippingsTitle/>} {...props}>
        <SimpleForm>
        <SelectProvince source="province" />
            <NumberInput
                source={'min_weight'}
                label="Peso mínimo"
                validate={[required(), minValue(0)]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            g/m
                        </InputAdornment>
                    ),
                }}
            />
            <NumberInput
                source={'max_weight'}
                label="Peso máximo"
                validate={[required(), minValue(0)]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            g/m
                        </InputAdornment>
                    ),
                }}
            />
            <NumberInput
                    source={'price'}
                    label="Precio"
                    validate={required()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        ),
                    }}
                />
        </SimpleForm>
    </Edit>
);