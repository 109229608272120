import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import DollarIcon from '@material-ui/icons/AttachMoney';
import ProductIcon from '@material-ui/icons/Collections';
import CustomerIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Bookmark';
import TagsIcon from '@material-ui/icons/LocalOffer';
import ShippingIcon from '@material-ui/icons/LocalShipping';

import { ProductList, ProductCreate, ProductEdit } from './views/products';
import { CustomerList, CustomerCreate, CustomerEdit } from './views/customers';
import { OrdersList, OrderEdit } from './views/orders';
import { TaxonomyList, TaxonomyCreate, TaxonomyEdit } from './views/taxonomies';
import { CategoriesCreate, CategoriesEdit, CategoriesList } from './views/categories';
import { ShippingsCreate, ShippingsEdit, ShippingsList } from './views/shippings';
import { TagsCreate, TagsEdit, TagsList } from './views/tags';
import { Dashboard } from './views/dashboard';
import CustomLayout from './components/layouts/CustomLayout';
import CustomRoutes from './routes/CustomRoutes';
import LoginWithTheme from './views/auth/Login';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import { messages } from './language/language-es';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const i18nProvider = polyglotI18nProvider(() => messages);

const App = () => (
  <Admin title="Eme Telas" loginPage={LoginWithTheme} authProvider={authProvider} layout={CustomLayout} customRoutes={CustomRoutes} dashboard={Dashboard} dataProvider={dataProvider} i18nProvider={i18nProvider}>
      <Resource name="products"  options={{label: messages.resources.products.name}} list={ProductList} edit={ProductEdit} create={ProductCreate} icon={ProductIcon} />
      {/* <Resource name="taxonomies"  options={{label: messages.resources.taxonomies.name}} list={TaxonomyList} edit={TaxonomyEdit} create={TaxonomyCreate} icon={ProductIcon} />*/}
      <Resource name="categories"  options={{label: messages.resources.categories.name}} list={CategoriesList} edit={CategoriesEdit} create={CategoriesCreate} icon={CategoryIcon} />
      <Resource name="tags" options={{label: messages.resources.tags.name}} list={TagsList} edit={TagsEdit} create={TagsCreate} icon={TagsIcon} />
      <Resource name="customers" options={{label: messages.resources.customers.name}} list={CustomerList} edit={CustomerEdit} create={CustomerCreate} icon={CustomerIcon} />
      <Resource name="orders" options={{label: messages.resources.orders.name}} list={OrdersList} edit={OrderEdit} icon={DollarIcon} />
      <Resource name="shippings"  options={{label: messages.resources.shippings.name}} list={ShippingsList} edit={ShippingsEdit} create={ShippingsCreate} icon={ShippingIcon} />
  </Admin>
);

export default App;
