import * as React from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    FormWithRedirect,
    Labeled,
    ReferenceField,
    SelectInput,
    Toolbar,
    SaveButton
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Basket from './Basket';
import Totals from './Totals';
import { OrderTitle } from './title'

const CustomerDetails = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Typography
            component={RouterLink}
            color="primary"
            to={`/customers/${record?.id}`}
            style={{ textDecoration: 'none' }}
        >
            {record?.first_name} {record?.last_name}
        </Typography>
        <Typography
            component={Link}
            color="primary"
            href={`mailto:${record?.email}`}
            style={{ textDecoration: 'none' }}
        >
            {record?.email}
        </Typography>
    </Box>
);

const ShippingAddress = ({ record }) => {
    if (!record) return null;

    if (record.shipping_method == "local") {
        return (
            <Box>
                <Typography>Retira por sucursal</Typography>
            </Box>
        )
    }
    return (
        <Box>
            <Typography>{record.address} {record.addr_number} {record.addr_floor_door}</Typography>
            <Typography>
                {record.city}, {record.province} ({record.zipcode})
            </Typography>
        </Box>
    )
};

const TextFieldReference = ({ record }) => (
    <span>Orden {record && record.id ? '#' + (String)(record.id).padStart(5, "0") : ''}</span>
);

const TextFieldStatusMP = ({ record }) => (
    <span>{record && record.mp_status ? record.mp_status : 'Pendiente'}</span>
);

const useEditStyles = makeStyles({
    root: { alignItems: 'flex-start' },
});

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderForm = (props) => {
    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <Box maxWidth="70em">
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Typography variant="h6" gutterBottom>
                                        Orden
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="created_at"
                                                resource="orders"
                                            >
                                                <DateField
                                                    source="created_at"
                                                    resource="orders"
                                                    record={formProps.record}
                                                />
                                            </Labeled>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="reference"
                                                resource="orders"
                                            >
                                                <TextFieldReference
                                                    source="id"
                                                    resource="orders"
                                                    record={formProps.record}
                                                />
                                            </Labeled>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <SelectInput
                                                resource="orders"
                                                source="status"
                                                choices={[
                                                    {
                                                        id: 'pending_payment',
                                                        name: 'Pendiente de pago',
                                                    },
                                                    {
                                                        id: 'processing',
                                                        name: 'Procesando',
                                                    },
                                                    {
                                                        id: 'complete',
                                                        name: 'Completado',
                                                    },
                                                    {
                                                        id: 'cancelled',
                                                        name: 'Cancelado',
                                                    },
                                                    {
                                                        id: 'failed',
                                                        name: 'Error',
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {formProps.record && formProps.record.payment_method == 'mp' &&
                                                <Box mt={2}>
                                                    <Labeled
                                                        source="mp_status"
                                                        resource="orders"
                                                    >
                                                        <TextFieldStatusMP
                                                            source="id"
                                                            resource="orders"
                                                            record={formProps.record}
                                                        />
                                                    </Labeled>
                                                </Box>
                                            }
                                            <Box mt={2}>
                                                <BooleanInput
                                                    row={true}
                                                    resource="orders"
                                                    source="payed"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Cliente
                                    </Typography>
                                    <ReferenceField
                                        source="customer_id"
                                        resource="orders"
                                        reference="customers"
                                        basePath="/customers"
                                        record={formProps.record}
                                        link={false}
                                    >
                                        <CustomerDetails />
                                    </ReferenceField>
                                    <Spacer />

                                    <Typography variant="h6" gutterBottom>
                                        Dirección de envío
                                    </Typography>
                                    <ShippingAddress record={formProps.record} />
                                </Grid>
                            </Grid>
                            <Spacer />

                            <Typography variant="h6" gutterBottom>
                                Productos
                            </Typography>
                            <Box>
                                <Basket record={formProps.record} />
                            </Box>
                            <Spacer />

                            <Typography variant="h6" gutterBottom>
                                TOTAL
                            </Typography>
                            <Box>
                                <Totals record={formProps.record} />
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            pristine={formProps.pristine}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="orders"
                        >
                            <SaveButton
                                disabled={formProps.pristine}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            />
                        </Toolbar>
                    </Card>
                </Box>
            )}
        />
    );
};

export const OrderEdit = (props) => {
    const classes = useEditStyles();
    return (
        <Edit
            title={<OrderTitle />}
            classes={classes}
            {...props}
            component="div"
        >
            <OrderForm />
        </Edit>
    );
};
