import * as React from "react";
import {
    BooleanField,
    List,
    Datagrid,
    TextField,
    EmailField,
    Filter,
    TextInput,
    EditButton,
    NumberField,
    DateField
} from 'react-admin';

const CustomerFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn />
    </Filter>
);

export const BooleanNumField = ({ record = {}, source }) => {
    const theRecord = { ...record };
    theRecord[source + 'Num'] = !!parseInt(record[source]);
    return <BooleanField record={theRecord} source={source + 'Num'} />
}

const DateFieldForTimestamp = ({ record = {}, source }) => {
    const theRecord = { ...record };
    const newDate = new Date(`${record[source]} EDT`);
    if (!isNaN(newDate)) {
        theRecord[source] = newDate;
    }
    return <DateField record={theRecord} source={source} />
}

export const CustomerList = props => {

    return (
        <List filters={<CustomerFilter />} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <EmailField source="email" />
                <NumberField
                    source="orders_count"
                    label="resources.customers.fields.orders_count"
                />
                <DateFieldForTimestamp source="birthday" />
                <BooleanNumField source="email_verified_at" label="Email verificado" />
                <EditButton />
            </Datagrid>
        </List>
    );
};