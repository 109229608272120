/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { useState, useEffect } from 'react'
import { useField } from 'react-final-form';
import { useDataProvider } from 'react-admin';

const CustomAutocomplete = withStyles({
    root:{
        marginBottom: "16px"
    },
    inputRoot: {
      '&[class*="MuiFilledInput-root"]': {
        paddingTop: "24px",
      }
    }
  })(Autocomplete);

const TagsField = ({ source, record = {} }) => {

    const [values, setValues] = useState([]);
    const { input } = useField(source);
    const dataProvider = useDataProvider();
    const [listTags, setListTags] = useState([]);

    useEffect(() => {
        dataProvider.getList('tags', { pagination: { page: 0, perPage: 100000 }, sort: { field: "name", order: "ASC" }, filter: {} })
            .then((data) => {
                setListTags(data.data);
            })
            .catch(error => {
                setListTags([]);
            });
    }, []);

    useEffect(() => {
        if (record[source]){
            const arr = listTags.filter(item => record[source].includes(item.id))
            setValues(arr);
        }
    }, [listTags,record[source]]);

    return (
        <CustomAutocomplete
            multiple
            value={values}
            onChange={(event, newValue) => {
                setValues([
                    ...newValue.filter((option) => listTags.indexOf(option) !== -1),
                ]);
                const newvalueinput = newValue.map(function(elem) {return elem.id;});
                input.onChange(newvalueinput);
            }}
            options={listTags}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} variant="filled" label="Etiquetas" />
            )}
        />
    );
}

export default TagsField;