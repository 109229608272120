import * as React from "react";
import {Create, SimpleForm, TextInput, required} from 'react-admin';

import {TagsTitle} from './title'

export const TagsCreate = props => (
    <Create title={<TagsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);