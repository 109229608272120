import { FunctionField } from "react-admin"
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const ORDER_STATUS_PENDING_PAYMENT = 'pending_payment';
const ORDER_STATUS_PROCESSING = 'processing';
const ORDER_STATUS_COMPLETE = 'complete';
const ORDER_STATUS_FAILED = 'failed';
const ORDER_STATUS_CANCELLED = 'cancelled';


const useStyles = makeStyles({
  pending_payment: { color: 'black' },
  processing: { color: 'orange' },
  cancelled: { color: 'black' },
  complete: { color: 'green' },
  failed: { color: 'red' },
});

const StatusField = ({ record, ...props }) => {
  const classes = useStyles();

  if(!record) return null;

  return (
    <FunctionField
      render={() =>
        record.status == ORDER_STATUS_PENDING_PAYMENT ? 'Pendiente de pago'
          : record.status == ORDER_STATUS_PROCESSING ? 'Procesando'
            : record.status == ORDER_STATUS_COMPLETE ? 'Completado'
              : record.status == ORDER_STATUS_CANCELLED ? 'Cancelado'
                : 'Error'
      }
      className={classnames({
        [classes.pending_payment]: record.status == ORDER_STATUS_PENDING_PAYMENT,
        [classes.processing]: record.status == ORDER_STATUS_PROCESSING,
        [classes.complete]: record.status == ORDER_STATUS_COMPLETE,
        [classes.cancelled]: record.status == ORDER_STATUS_CANCELLED,
        [classes.failed]: record.status == ORDER_STATUS_FAILED,
      })}
      {...props}
    />
  )
}

export default StatusField;