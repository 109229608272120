import * as React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import CardWithIcon from './CardWithIcon';

const NbNewOrders = ({ value }) => {

    return (
        <CardWithIcon
            to="/orders"
            icon={ShoppingCartIcon}
            title={"Órdenes Nuevas"}
            subtitle={value}
        />
    );
};

export default NbNewOrders;
